<template>
    <div>
        <div class="right-menu shipping-right">
            
            <subHeader pageName="BRANDS" />

            <tutorialsAction />

            <div class="db_top">
                <div class="db-container">
          
                    <moduleStats/>
                    
                    <!-- search block starts here -->
                    <div class="formOptionsBox">
                        <div class="searchOptionsWrap">
                            <div class="searchBlock" id="brand-search">
                                <form @submit.prevent="">
                                    <div class="searchIconWrap">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.491 17.49">
                                            <path id="Path_10" data-name="Path 10" d="M16,17.49h0L11,12.5v-.79l-.27-.28a6.512,6.512,0,1,1,.7-.7l.28.27h.79l4.99,5L16,17.489ZM6.5,2A4.5,4.5,0,1,0,11,6.5,4.505,4.505,0,0,0,6.5,2Z" fill="#848484"/>
                                        </svg>
                                    </div>
                                    <input
                                        type="text"
                                        name="search"
                                        id="search"
                                        ref="searchInput"
                                        placeholder="Search"
                                        v-model="searchedItem"
                                        @input="searchBrand()"
                                        :disabled="tourIsActive"
                                    />
                                </form>
                            </div>
                            <div class="filterShowTabsRow" v-if="searchedItem.length > 0">
                                <div class="filterShowTabsListSearchedQuery">
                                    <p>Search results for "{{ searchedItem.length > 20 ? searchedItem.substr(0, 20) + "..." : searchedItem }}".</p>
                                </div>
                                <div class="filterShowTabsResetBtn">
                                    <button @click.prevent="clearSearch">Clear</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- search block ends here -->

                    <!-- data table starts here -->
                    <div class="tableMainWrapBox" style="margin-top: 25px;position: relative;" id="preview-record">
                        <div class="tableScrollWrap" ref="brandsTable">
                            <table>
                                <thead>
                                    <tr>
                                        <th>
                                            <div class="ctmCheckWrap">
                                                <label class="ctmTableCheckContainer">
                                                    <input 
                                                        type="checkbox"
                                                        @click="toggleIsCheckedAll()"
                                                        :checked="checkedItems.length != 0 && checkedItems.length == brandsList.length"
                                                        :disabled="tourIsActive || this.previewForm.id != ''"
                                                    >
                                                    <span class="checkmark"></span>
                                                </label>
                                                <div class="ctmTableImgWrap">
                                                    <p data-toggle="tooltip" data-placement="top" title="Image">Image</p>
                                                </div>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Name">Name</p>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Description">Description</p>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Active/Hide">Active/Hide</p>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Action">Action</p>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody v-if="brandsLoading">
                                    <tr v-for="i in 5" :key="i">
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="20"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                                <div class="ctmDataTableImgBox">
                                                    <vue-skeleton-loader
                                                        type="circle"
                                                        :width="36"
                                                        :height="36"
                                                        animation="fade"
                                                    />
                                                </div> 
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="150"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="tableBodyCtmTxt">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="700"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td style="position: relative;">
                                            <vue-skeleton-loader
                                                type="rect"
                                                :width="95"
                                                :height="20"
                                                animation="fade"
                                            />
                                        </td>
                                        <td>
                                            <vue-skeleton-loader
                                                type="rect"
                                                :width="102"
                                                :height="20"
                                                animation="fade"
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr v-if="brandsList && brandsList.length == 0">
                                        <td colspan="6" style="text-align:center"><p class="mb-0">No Brands Available</p></td>
                                    </tr>
                                    <tr v-for="(brand, index) in brandsList" :key="index">
                                        
                                        <!-- checkbox & image block starts here -->
                                        <td @click="previewBrand(brand,index,'input',$event)">
                                            <div class="ctmCheckWrap" data-open="true">
                                                <label class="ctmTableCheckContainer" data-open="false" @click.prevent=" brand.id != -1 ? checkedItemHandle(brand.id) : ''">
                                                    <input 
                                                        type="checkbox" 
                                                        v-model="checkedItems" 
                                                        :value="brand.id" 
                                                        :disabled="tourIsActive || brand.id == -1" 
                                                        data-open="false"
                                                    >
                                                    <span class="checkmark" data-open="false"></span>
                                                </label>
                                                <!-- for saved brand -->
                                                <div class="ctmDataTableImgBox" data-open="true" v-if="brand.id != -1">
                                                    <img 
                                                        :src="brand.image ? brand.image_src : '/images/no_image.png'" 
                                                        alt=""
                                                        v-if="brand.id == -1"
                                                        data-open="true"
                                                        @error="handleImageError"
                                                    >
                                                    <img
                                                        :src="generateUrl(brand) ? generateUrl(brand) : '/images/no_image.png'"
                                                        alt=""
                                                        data-open="true"
                                                        @error="handleImageError"
                                                        v-else
                                                    >
                                                </div>
                                                <!-- for unsaved brand -->
                                                <div class="ctmDataTableImgBox" data-open="true" v-else>
                                                    <img 
                                                        :src="brand.addImage ? brand.addImage_src : '/images/no_image.png'" 
                                                        alt=""
                                                        v-if="brand.id == -1"
                                                        data-open="true"
                                                        @error="handleImageError"
                                                    >
                                                    <img
                                                        :src="generateUrl(brand) ? generateUrl(brand) : '/images/no_image.png'"
                                                        alt=""
                                                        data-open="true"
                                                        @error="handleImageError"
                                                        v-else
                                                    >
                                                </div>
                                            </div>
                                        </td>
                                        <!-- checkbox & image block ends here -->

                                        <!-- brand name block starts here -->
                                        <td class="mainTableTitle" @click="previewBrand(brand,index,'input',$event)">
                                            <div class="editFieldTitle" data-open="true" id="brand-name-field-3">
                                                <input 
                                                    type="text"
                                                    placeholder="Write a brand name" 
                                                    v-model="brand.name" 
                                                    @focus="brandSelection(brand)" 
                                                    @blur="handleOTGUpdate(brand,index)" 
                                                    :disabled="tourIsActive"
                                                    data-open="false"
                                                >
                                            </div>
                                        </td>
                                        <!-- brand name block ends here -->

                                        <!-- brand description block starts here -->
                                        <td @click="previewBrand(brand,index,'solid',$event)">
                                            <div class="tableBodyCtmTxt" data-open="true">
                                                <p data-open="true">
                                                    {{ brand.description ? brand.description.length > 100 ? brand.description.substr(0, 100) + "..." : brand.description : '--' }}
                                                </p>
                                            </div>
                                        </td>
                                        <!-- brand description block ends here -->

                                        <!-- active/disabled block starts here-->
                                        <td @click="previewBrand(brand,index,'input',$event)">
                                            <div class="actionSwitch" data-open="true">
                                                <label class="switch" data-open="false" v-if="brand.id != -1">
                                                    <input
                                                        type="checkbox"
                                                        :id="`customSwitch-p-${brand.id}`"
                                                        :value="brand.isActive"
                                                        v-model="brand.isActive"
                                                        :disabled="tourIsActive"
                                                        data-open="false"
                                                    >
                                                    <span class="slider" data-open="false" @click.stop="updateBrandStatus(brand,index)"></span>
                                                </label>
                                            </div>
                                        </td>
                                        <!-- active/disabled block ends here-->

                                        <!-- action block starts here -->
                                        <td @click="previewBrand(brand,index,'input',$event)">
                                            <div class="ctmDataTableActionBtn" v-if="isDeletingItem(brand.id) && deleteLoader" data-open="true">
                                                <loaderBtn />
                                            </div>
                                            <div class="ctmDataTableActionBtn" data-open="true" v-else>
                                                <button class="delFunction" @click.prevent="deleteBrandHandle(brand,index)" :disabled="tourIsActive" data-open="false">
                                                    <i aria-hidden="true" class="fa fa-trash-o" data-open="false"></i>
                                                </button>
                                            </div>
                                        </td>
                                        <!-- action block ends here -->

                                    </tr>
                                    
                                    <!-- load more block starts here -->
                                    <tr v-if="isPending">
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="20"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                                <div class="ctmDataTableImgBox">
                                                    <vue-skeleton-loader
                                                        type="circle"
                                                        :width="36"
                                                        :height="36"
                                                        animation="fade"
                                                    />
                                                </div> 
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="150"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="tableBodyCtmTxt">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="700"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td style="position: relative;">
                                            <vue-skeleton-loader
                                                type="rect"
                                                :width="95"
                                                :height="20"
                                                animation="fade"
                                            />
                                        </td>
                                        <td>
                                            <vue-skeleton-loader
                                                type="rect"
                                                :width="102"
                                                :height="20"
                                                animation="fade"
                                            />
                                        </td>
                                    </tr>
                                    <!-- load more block ends here -->

                                </tbody>
                            </table>
                        </div>

                        <!-- add/update brand form starts here -->
                        <div class="productSlideBox" ref="viewInputForm" :class="previewForm.id ? 'activeRow': ''" >
                            <div class="tableActionBtns" id="brand-details-preview">
                                <div class="productTableSideBar">
                                    <div class="row">
                                        <div class="col-md-8" id="brand-name-field-2">
                                            <div class="viewOrderTitle">
                                                <input 
                                                    type="text" 
                                                    placeholder="Write a brand name" 
                                                    v-model="previewForm.name" 
                                                    @input="slugGeneration('name')"
                                                    :disabled="tourIsActive"
                                                >
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="orderViewNav">
                                                <ul>
                                                    <el-tooltip class="box-item" effect="dark" content="Expand" placement="top-start">
                                                        <li class="sm-extand-hide">
                                                            <button @click.prevent="expand" :disabled="tourIsActive" id="expand-form">
                                                                <svg viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
                                                                    <g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" transform="translate(2 2)">
                                                                        <path d="m16.5 5.5v-4.978l-5.5.014"/>
                                                                        <path d="m16.5.522-6 5.907"/>
                                                                        <path d="m11 16.521 5.5.002-.013-5.5"/>
                                                                        <path d="m16.5 16.429-6-5.907"/>
                                                                        <path d="m.5 5.5v-5h5.5"/>
                                                                        <path d="m6.5 6.429-6-5.907"/>
                                                                        <path d="m6 16.516-5.5.007v-5.023"/>
                                                                        <path d="m6.5 10.5-6 6"/>
                                                                    </g>
                                                                </svg>
                                                            </button>
                                                        </li>
                                                    </el-tooltip>
                                                    <el-tooltip class="box-item" effect="dark" content="Close" placement="top-start">
                                                        <li>
                                                            <button @click.prevent="closePreview" id="closeSide" :disabled="tourIsActive">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="29.446" height="33.409" viewBox="0 0 29.446 33.409">
                                                                    <g id="Group_20906" data-name="Group 20906" transform="translate(362.041 -2185.5)">
                                                                        <path id="Path_57522" data-name="Path 57522" d="M-361.041,2186.648v31.409" transform="translate(0 -0.148)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="2"/>
                                                                        <g id="Group_20905" data-name="Group 20905" transform="translate(0 -0.5)">
                                                                            <path id="Path_57523" data-name="Path 57523" d="M-354.018,2202.5h20.265" transform="translate(0 0)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="2"/>
                                                                            <path id="Path_57524" data-name="Path 57524" d="M-342.6,2193.747l8,9.15-8,8.85" transform="translate(1 -0.247)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                                                        </g>
                                                                    </g>
                                                                </svg>
                                                            </button>
                                                        </li>
                                                    </el-tooltip>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="sectionTabsLink">
                                    <ul onclick="window.myFunction(event)">
                                        <li v-if="!tourIsActive">
                                            <a class="active" id="category-detail-navbtn" href="#brandDetails">Brand Info</a>
                                        </li>
                                        <li v-if="!tourIsActive">
                                            <a href="#seo" id="category-seo-navbtn">SEO Essentials</a>
                                        </li>
                                    </ul>
                                    <div class="updateBtnBox" v-if="brandIsSaving">
                                        <loaderBtn/>
                                    </div>
                                    <div class="updateBtnBox" id="save-brand-btn" v-else>
                                        <button 
                                            form="brand-information" 
                                            button="submit" 
                                            :disabled="tourIsActive"
                                        >
                                            {{ previewForm.id == -1 ? 'Save' : 'Update' }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <ValidationObserver ref="brandFormObserver">
                                <div class="productTableInfoBlock">
                                    <div class="productTableInfoWhiteBox" id="brandDetails">
                                        <form id="brand-information" @submit.prevent="validate()">
                                            <div class="tableFromFieldBox">
                                                <label for="brand-name-field-1">Name<span>*</span></label>
                                                <ValidationProvider name="name" rules="required|max:250" v-slot="{ errors }">
                                                    <div class="tableFromFieldItem">
                                                        <input 
                                                            type="text" 
                                                            name="brand-name-field-1" 
                                                            id="brand-name-field-1" 
                                                            placeholder="Name" 
                                                            v-model="previewForm.name" 
                                                            @input="slugGeneration('name')"
                                                            :disabled="tourIsActive"
                                                        >
                                                        <p class="fieldSeoMessage" v-if="previewForm.name && previewForm.name.length <= 50">
                                                            <span style="display: inline-flex;margin-right: 5px;">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                                                    <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                                                                </svg> 
                                                            </span>
                                                            Do not exceed 50 characters when entering the brand name for SEO
                                                        </p>
                                                        <p class="fieldSeoMessage" v-else-if="previewForm.name && previewForm.name.length > 50"> 
                                                            Not SEO friendly
                                                        </p>
                                                        <p class="remainingTxt" v-if="previewForm.name">
                                                            <span>
                                                                {{previewForm.name ? previewForm.name.length <= 50 ? 50 - previewForm.name.length : 0 : 50 }} 
                                                            </span>
                                                            Remaining
                                                        </p>
                                                    </div>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                            <div class="tableFromFieldBox">
                                                <label for="brand-slug-field">Slug<span>*</span></label>
                                                <ValidationProvider name="slug" rules="required|max:250" v-slot="{ errors }">
                                                    <div class="tableFromFieldItem">
                                                        <input 
                                                            type="text" 
                                                            name="brand-slug-field" 
                                                            id="brand-slug-field" 
                                                            placeholder="Slug" 
                                                            v-model="previewForm.slug" 
                                                            @input="slugGeneration('slug')"
                                                            :disabled="tourIsActive"
                                                        >
                                                        <p class="fieldSeoMessage" v-if="previewForm.slug && previewForm.slug.length <= 60">
                                                            <span style="display: inline-flex;margin-right: 5px;">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                                                    <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z" />
                                                                </svg> 
                                                            </span>
                                                            Do not exceed 60 characters when entering the brand slug for SEO
                                                        </p>
                                                        <p class="fieldSeoMessage" v-else-if="previewForm.slug && previewForm.slug.length > 60"> 
                                                            Not SEO friendly
                                                        </p>
                                                        <p class="remainingTxt" v-if="previewForm.slug">
                                                            <span>
                                                                {{previewForm.slug ? previewForm.slug.length <= 60 ? 60 - previewForm.slug.length : 0 : 60 }} 
                                                            </span>
                                                            Remaining
                                                        </p>
                                                    </div>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                            <div class="tableFromFieldBox">
                                                <ValidationProvider name="description" rules="required|max:3000" v-slot="{ errors }">
                                                    <label for="brand-description">Description<span>*</span></label>
                                                    <div class="tableFromFieldItem">
                                                        <textarea 
                                                            name="brand-description" 
                                                            id="brand-description" 
                                                            placeholder="Description" 
                                                            v-model="previewForm.description"
                                                            :disabled="tourIsActive"
                                                        >
                                                        </textarea>
                                                    </div>
                                                    <span class="text-danger" >{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                            <div class="tableFromFieldBox" id="brand-image" v-if="previewForm.id == -1">
                                                <label for="sDiscription">Image<span>*</span></label>
                                                <div class="imgUploaderTxt">
                                                    <input 
                                                        type="file" 
                                                        ref="addPreviewFileInput" 
                                                        @change.prevent="addFormImageChangeHande" 
                                                        id="feature_img" 
                                                        accept="image/png,image/jpg,image/jpeg,image/webp" 
                                                        hidden 
                                                        :disabled="tourIsActive"
                                                    >
                                                    <label for="feature_img">
                                                        <div class="category-tab-img" id="brand-featured-image">
                                                            <img 
                                                                :src="previewForm.addImage ? previewForm.addImage_src ? previewForm.addImage_src : base_image_url + setting.store_id + '/brand/' + previewForm.addImage : '/images/no_image.png' " 
                                                                alt=""
                                                                @error="handleImageError"
                                                            />
                                                        </div>
                                                    </label>
                                                </div>
                                                <span class="text-danger" v-if="imageValidations.imgIsRequired">Image field is required</span>
                                            </div>
                                            <div class="tableFromFieldBox" id="brand-image-update" v-else>
                                                <label for="brandImage">Image<span>*</span></label>
                                                <div class="imgUploaderTxt">
                                                    <input 
                                                        type="file" 
                                                        ref="previewFileInput" 
                                                        @change.prevent="updateFormImageChangeHandle" 
                                                        id="feature_img1" 
                                                        accept="image/png,image/jpg,image/jpeg,image/webp" 
                                                        hidden 
                                                        :disabled="tourIsActive"
                                                    >
                                                    <label for="feature_img1">
                                                        <div class="category-tab-img" id="brand-featured-image-update">
                                                            <img 
                                                                :src="previewForm.image ? previewForm.image_src ? previewForm.image_src : base_image_url + setting.store_id + '/brand/' + previewForm.image : '/images/no_image.png' " 
                                                                alt=""
                                                                @error="handleImageError"
                                                            />
                                                        </div>
                                                    </label>
                                                </div>
                                                <span class="text-danger" v-if="imageValidations.imgIsRequired">Image field is required</span>
                                            </div>
                                        </form>
                                    </div>
                                    <div class="productTableInfoWhiteBox" id="seo">
                                        <div class="productTableInfoWhiteBoxTitle">
                                            <h5>SEO</h5>
                                        </div>
                                        <div class="tableFromFieldBox">
                                            <ValidationProvider name="meta title" rules="max:250" v-slot="{ errors }">
                                                <label for="brand-meta-title">Meta Title</label>
                                                <div class="tableFromFieldItem">
                                                    <input 
                                                        type="text" 
                                                        name="brand-meta-title" 
                                                        id="brand-meta-title" 
                                                        placeholder="Meta title" 
                                                        form="brand-information"
                                                        v-model="previewForm.meta_title"
                                                        :disabled="tourIsActive"
                                                    >
                                                    <p class="fieldSeoMessage" v-if="previewForm.meta_title && previewForm.meta_title.length <= 60">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                                            <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                                                        </svg> 
                                                        Do not exceed 60 characters when entering the category meta title for SEO
                                                    </p>
                                                    <p class="fieldSeoMessage" v-else-if="previewForm.meta_title && previewForm.meta_title.length > 60">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                                            <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                                                        </svg> 
                                                        Not SEO friendly
                                                    </p>
                                                    <p class="remainingTxt" v-if="previewForm.meta_title">
                                                        <span>
                                                            {{previewForm.meta_title ? previewForm.meta_title.length <= 60 ? 60 - previewForm.meta_title.length : 0 : 60 }} 
                                                        </span>
                                                        Remaining
                                                    </p>
                                                </div>
                                                <span class="text-danger" >{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </div>
                                        <div class="tableFromFieldBox">
                                            <ValidationProvider name="meta description" rules="max:500" v-slot="{ errors }">
                                                <label for="brand-meta-description">Meta Description</label>
                                                <div class="tableFromFieldItem">
                                                    <textarea 
                                                        name="brand-meta-description" 
                                                        id="brand-meta-description" 
                                                        placeholder="Meta description" 
                                                        form="brand-information"
                                                        v-model="previewForm.meta_description"
                                                        :disabled="tourIsActive"
                                                    >
                                                    </textarea>
                                                    <p class="fieldSeoMessage" v-if="previewForm.meta_description && previewForm.meta_description.length <= 300">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                                            <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                                                        </svg> 
                                                        Do not exceed 300 characters when entering the category meta desscription for SEO
                                                    </p>
                                                    <p class="fieldSeoMessage" v-else-if="previewForm.meta_description && previewForm.meta_description.length > 300">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                                            <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                                                        </svg> 
                                                        Not SEO friendly
                                                    </p>
                                                    <p class="remainingTxt" v-if="previewForm.meta_description">
                                                        <span>
                                                            {{previewForm.meta_description ? previewForm.meta_description.length <= 300 ? 300 - previewForm.meta_description.length : 0 : 300 }} 
                                                        </span>Remaining
                                                    </p>
                                                </div>
                                                <span class="text-danger" >{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </div>
                                        <div class="ctmFlexRow">
                                            <div class="statusColumnBox">
                                                <div class="tableFromFieldBox" id="brand-active-switch">
                                                    <label>Status</label>
                                                    <div class="actionSwitch">
                                                        <label class="switch">
                                                        <input 
                                                            type="checkbox"
                                                            :value="previewForm.isActive"
                                                            v-model="previewForm.isActive"
                                                            :disabled="tourIsActive"
                                                        >
                                                        <span class="slider"></span>
                                                        <p class="ctmSwitchLabel">{{previewForm.isActive ? 'Active' : 'inActive'}}</p>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="statusColumnBox">
                                                <div class="tableFromFieldBox" id="brand-indexed-switch">
                                                    <label>Indexed</label>
                                                    <div class="actionSwitch">
                                                        <label class="switch">
                                                        <input
                                                            type="checkbox"
                                                            :value="previewForm.isIndexed"
                                                            v-model="previewForm.isIndexed"
                                                            :disabled="tourIsActive"
                                                        >
                                                        <span class="slider"></span>
                                                        <p class="ctmSwitchLabel">{{previewForm.isIndexed ? 'Yes' : 'No'}}</p>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ValidationObserver>
                        </div>
                        <!-- add/update brand form ends here -->

                    </div>
                    <!-- data table starts here -->

                    <!--bottom action bar starts here-->
                    <div class="row product-bottom-bar m-0" v-if="checkedItems.length > 1">
                        <div class="col-md-6 model-footer-main1">
                            <div class="bottom-bar">
                                <a href="" class="model-footer-1 mr-5" @click.prevent="toggleIsCheckedAll()" v-if="checkedItems.length != brandsList.length">
                                    <i class="fa fa-check-square-o" aria-hidden="true"></i>
                                    <p>SELECT ALL</p>
                                </a>
                                <a href="" class="model-footer-1" @click.prevent="clearSelection()">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                                        <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
                                    </svg>
                                    <p>CLEAR SELECTION</p>
                                </a>
                            </div>
                            <div class="model-footer-2">{{ checkedItems.length }}</div>
                        </div>
                        <div class="col-md-6 model-footer-main1 product-row-reverse">
                            <div class="bottom-bar">
                                <a href="" class="model-footer-1 mr-4" @click.prevent="brandsBulkActionHandle('active')">
                                    <p>ACTIVE</p>
                                </a>
                                <a href="" class="model-footer-1 mr-4" @click.prevent="brandsBulkActionHandle('disable')">
                                    <p>DISABLE</p>
                                </a>
                                <a href="" class="model-footer-1 ml-4" @click.prevent="brandsBulkActionHandle('delete')">
                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                    <p>DELETE</p>
                                </a>
                            </div>
                        </div>
                    </div>
                    <!--bottom action bar ends here-->

                </div>
            </div>

        </div>

        <!--vue tour-->
        <v-tour name="brandsTour" :steps="tourSteps" :options="tourOptions" :callbacks="tourCallbacks"></v-tour>

    </div>
</template>

<script>
import subHeader from "@/components/subHeader.vue";
import moduleStats from "@/components/moduleStats.vue";
import tutorialsAction from "@/components/tutorialsAction.vue";
import _ from 'lodash';
import { mapGetters } from 'vuex';
import {MessageBox, Message, Loading} from 'element-ui'
import loaderBtn from "@/components/loaderBtn.vue";

export default {
    metaInfo() {
        return {
            title: 'E-Commerce | Brands | YeetCommerce',
        };
    },
    data(){
        return{
            base_image_url: process.env.VUE_APP_STORE_IMAGE_BASE_URL,

            //search start
            searchedItem: '',
            //search end

            //brands start
            previewForm:{
                id: '',
                name: '',
                slug: '',
                description: '',
                meta_title: '',
                meta_description: '',
                isActive: true,
                isIndexed: true,
                isTouched: false,
                image: null,
                image_src: '',
                addImage: null,
                addImage_src: '',
            },
            tempPreviewBrand:{
                id: '',
                name: '',
                slug: '',
                description: '',
                meta_title: '',
                meta_description: '',
                isActive: true,
                isIndexed: true,
                isTouched: false,
                image: null,
                image_src: '',
                addImage: null,
                addImage_src: '',
            },
            tempBrand:{
                id: '',
                name: '',
                slug: '',
                description: '',
                meta_title: '',
                meta_description: '',
                isActive: true,
                isIndexed: true,
                isTouched: false,
                image: null,
                image_src: '',
            },
            defaultPreviewForm:{
                id: '',
                name: '',
                slug: '',
                description: '',
                meta_title: '',
                meta_description: '',
                isActive: true,
                isIndexed: true,
                isTouched: false,
                image: null,
                image_src: '',
                addImage: null,
                addImage_src: '',
            },
            brandsLoading: false,
            brandsList: [],
            brandSave: false,
            brandIsSaving: false,
            //brands end
            //image validations start
            imageValidations:{
                imgIsRequired: false,
                imgInvalidFormat: false,
                isNotImg: false,
            },
            //image validations end
            checkedItems: [],
            deletingItems: [],
            deleteLoader: false,
            timer: undefined,
            isMasterSearched: false,

            //tour start
            tourOptions: {
                useKeyboardNavigation: false,
                labels: {
                    buttonSkip: 'Skip Tour',
                    buttonPrevious: 'Previous',
                    buttonNext: 'Next',
                    buttonStop: 'Finish Tour'
                }
            },
            tourCallbacks: {
                onPreviousStep: this.handlePreviousStep,
                onNextStep: this.handleNextStep,
                // onStart: this.handleTourStart,
                onStop: this.handleTourStop,
                onFinish: this.handleTourFinish,
                onSkip: this.handleTourSkip
            },
            tourSteps:[
                //header steps
                {
                    target: '#tour-start-btn',
                    content: `<strong>Start Tour</strong><br>Provides insights into the available functionalities within a specific menu, helping you understand what actions you can perform.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#master-search',
                    content: `<strong>Master Search</strong><br>Enables you to perform searches from all over the dashboard.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    },
                },
                {
                    target: '#add-new-dropDown',
                    content: `<strong>Add New Record</strong><br>Enables you to add new orders, products and categories on the go.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                    }
                },
                {
                    target: '#help-link',
                    content: `<strong>Help</strong><br>If you require any assistance, feel free to reach out to us.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#user-options-dropdown',
                    content: `<strong>User Information</strong><br>Presents essential details about you along with a selection of available options.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                //search bar step
                {
                    target: '#brand-search',
                    content: `<strong>Search Brand</strong><br>It enables you to search specific brands.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'bottom'
                    }
                },
                //form steps
                {
                    target: '#preview-record',
                    content: `<strong>Brands Table</strong><br>You can preview and edit the added brands by clicking on each individual record present in the table.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'top'
                    }
                },
                {
                    target: '#add-new-record',
                    content: `Add a <strong>Brand</strong><br>By clicking this button, you can add a new brand and open a form to input and save the details.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#brand-details-preview',
                    content: `<strong>Brand Details Form</strong><br>This form can be used to add details about the brand and save them.`,
                    params: {
                        highlight: false,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#brand-name-field-1',
                    content: `<strong>Brand Name Field</strong><br>You can add the name of brand using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#brand-slug-field',
                    content: `<strong>Brand Slug Field</strong><br>Slug of brand can be added using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'left'
                    }
                },
                {
                    target: '#brand-description',
                    content: `<strong>Brand Description</strong><br>brand description can be added using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#brand-featured-image',
                    content: `<strong>Brand Image</strong><br>It enables you to add image for the brand.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#brand-meta-title',
                    content: `<strong>Brand Meta Title</strong><br>Meta title for the brand can be added using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#brand-meta-description',
                    content: `<strong>Brand Meta Description</strong><br>Meta description for the brand can be added using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#brand-active-switch',
                    content: `<strong>Brand Status</strong><br>Brand status can be changed between active and inactive using this switch.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'right'
                    }
                },
                {
                    target: '#brand-indexed-switch',
                    content: `<strong>Brand Indexed Status</strong><br>Brand index status can be changed between yes and no using this switch.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'right'
                    }
                },
                {
                    target: '#brand-name-field-3',
                    content: `<strong>Add Brand Name</strong><br>You can add/update the name of brand through this field as well.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'top'
                    }
                },
                {
                    target: '#brand-name-field-2',
                    content: `<strong>Brand Name Field</strong><br>You can add the name of brand using this field too.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'right'
                    }
                },
                {
                    target: '#expand-form',
                    content: `<strong>Expand Form</strong><br>Form can be expanded or collapsed by clicking this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'left'
                    }
                },
                {
                    target: '#closeSide',
                    content: `<strong>Close Form</strong><br>Form can be closed using this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'left'
                    }
                },
                {
                    target: '#save-brand-btn',
                    content: `<strong>Save Brand Details</strong><br>Added brand details can be saved by clicking this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'left'
                    }
                },
            ],
            tourIsActive: false,
            //tour end
        }
    },
    components:{
        subHeader,
        tutorialsAction,
        moduleStats,
        loaderBtn
    },
    computed:{
        ...mapGetters({
            setting: 'settings_module/settings',
            storedBrands: 'brands_module/brands',
            brandsCurrentPage: 'brands_module/currentPage',
            brandsLastPage: 'brands_module/lastPage',
            brandsSearchKeyword:'brands_module/keyword',
            isPending: 'brands_module/isPending',
        }),
    },
    watch:{
        'storedBrands':{
            handler:function(value){

                this.brandsLoading = false;

                if(this.previewForm.id == -1){
                    
                    this.brandsList = _.cloneDeep(value);

                    this.brandsList.unshift(this.previewForm);

                }else if(this.tempPreviewBrand.id != ''){
                    
                    this.brandsList = _.cloneDeep(value);

                    this.brandsList[this.previewForm.index] = this.previewForm;

                }else{

                    this.brandsList = _.cloneDeep(value);

                }

                if(this.isMasterSearched){

                    this.previewSearchedBrand(this.brandsList[0],0);

                    this.isMasterSearched = false;
                }

            },deep:true
        },
        'previewForm':{
            handler:function(value){

                if((value.id == -1) && (value.name || value.slug  || value.description || value.image || value.addImage || value.meta_title 
                || value.meta_description )){

                    value.isTouched = true;

                }else{

                    value.isTouched = false;

                }

            },deep:true
        },
    },
    methods:{
        //infinite scroll start
        handleScrollEvent(event){

            let element = this.$refs.brandsTable;

            let maxScrollTop = element.scrollHeight - element.clientHeight;

            if(element.scrollTop >= maxScrollTop - 100){

                if(this.isPending == false && this.brandsCurrentPage != this.brandsLastPage){

                    this.$store.dispatch('brands_module/fetchBrandsRequest',{ size: 15, page: this.brandsCurrentPage + 1, loadData:'more' });

                }

            }
            
        },
        //infinite scroll start

        //fields handling start
        slugGeneration(field){

            let slug = '';

            if(field == 'name'){

                slug = this.previewForm.name.toLowerCase();
                slug = slug.replace(/[^a-zA-Z0-9 ]/g, '');
                slug = slug.replace(/\s/g, '-');
                slug = slug.replace(/(\W)\W*/g, '$1');
                slug = slug.replace(/-$/, ""); 

            }else if(field == 'slug'){

                slug = this.previewForm.slug.toLowerCase();
                slug = slug.replace(/[^a-zA-Z0-9- ]/g, '');
                slug = slug.replace(/\s/g, '-');
                slug = slug.replace(/(\W)\W*/g, '$1');

            }
            
            this.previewForm.slug = slug;

        },
        //fields handling end

        //OTG update start
        brandSelection(brand){

            this.tempBrand = _.cloneDeep(brand);

        },
        async handleOTGUpdate(brand,index){
            
            if(this.previewForm.id == ''){

                if(brand.id != -1){

                    let isRequired = false;

                    if(brand.name.length == 0){

                        this.$notify({
                            type: 'warning',
                            title: 'Alert',
                            message: "The brand name field is required.",
                        });

                        isRequired = true;

                    }

                    if(brand.name.length > 250){

                        this.$notify({
                            type: 'warning',
                            title: 'Alert',
                            message: "The brand name may not be greater than 250 characters.",
                        });

                        isRequired = true;

                    }

                    if(isRequired){

                        this.brandsList[index].name = this.tempBrand.name;

                    }

                    let isChanged = false

                    if(this.tempBrand.name != brand.name){
                        isChanged = true
                    }

                    if(isChanged){

                        MessageBox.confirm(
                            'You have unsaved changes, save and proceed?',
                            'Confirm',
                            {
                                distinguishCancelAndClose: true,
                                confirmButtonText: 'Save',
                                cancelButtonText: 'Discard Changes',
                            }
                        ).then(async () => {

                            let loader = Loading.service({
                                text: "The brand is being updated. Please wait!",
                                fullscreen: true,
                            });

                            //updating brand name
                            let slug = brand.name.toLowerCase();
                            slug = slug.replace(/[^a-zA-Z0-9 ]/g, '');
                            slug = slug.replace(/\s/g, '-')
                            slug = slug.replace(/(\W)\W*/g, '$1')
                            slug = slug.replace(/-$/, "");              //eliminate final hyphen (if exists)

                            brand.name = brand.name.trim();
                            brand.slug = slug;

                            let formData = new FormData();
                            let form = brand;

                            formData.append('brand_id',form.id)
                            formData.append('name', form.name.trim());
                            formData.append('slug', form.slug);

                            try{

                                let res = await this.$axios.post('/brand/update', formData);
                                if(res.data.status_code == '1392'){

                                    this.$notify({
                                        type: 'success',
                                        title: 'Success',
                                        message: res.data.message,
                                    });

                                    let payload = {
                                        recordIndex: index,
                                        brand: res.data.brand
                                    }

                                    this.$store.commit('brands_module/update_brands_list',payload);

                                    this.$store.commit('gallery_module/CLEAR_MODULE');

                                }

                            }catch(error){

                                this.brandsList[index].name = this.tempBrand.name;
                                this.brandsList[index].slug = this.tempBrand.slug;

                                if(error.response){

                                    if(error.response.data.error.brand_id){

                                        this.$message({
                                            type: 'error',
                                            showClose: true,
                                            message: error.response.data.error.brand_id[0],
                                        });

                                    }else if(error.response.data.error.name){

                                        this.$message({
                                            type: 'error',
                                            showClose: true,
                                            message: error.response.data.error.name[0],
                                        });

                                    }else if(error.response.data.error.slug){

                                        this.$message({
                                            type: 'error',
                                            showClose: true,
                                            message: error.response.data.error.slug[0],
                                        });

                                    }else if(error.response.data.error.includes('storage limit has been reached')){
                                        
                                        this.$message({
                                            type: 'error',
                                            showClose: true,
                                            message: error.response.data.error,
                                        });

                                    }else{

                                        this.$message({
                                            type: 'error',
                                            showClose: true,
                                            message: error.response.data.message,
                                        });

                                    }

                                }else{

                                    this.$message({
                                        type: "error",
                                        showClose: true,
                                        message: error.message,
                                    });

                                }

                            }finally{

                                loader.close();

                            }

                        }).catch(()=>{

                            this.brandsList[index].name = this.tempBrand.name;
                            this.brandsList[index].slug = this.tempBrand.slug;

                            this.$notify({
                                type: "info",
                                title: "Changes Discarded",
                                message: "Changes discarded successfully.",
                            });

                            this.tempBrand = [];

                        });

                    }
                    
                }

            }else{

                if(brand.id != -1){

                    if(brand.id != this.previewForm.id){
                    
                        let isRequired = false;

                        if(brand.name.length == 0){

                            this.$notify({
                                type: 'warning',
                                title: 'Alert',
                                message: "The brand name field is required.",
                            });

                            isRequired = true;

                        }

                        if(brand.name.length > 250){

                            this.$notify({
                                type: 'warning',
                                title: 'Alert',
                                message: "The brand name may not be greater than 250 characters.",
                            });

                            isRequired = true;

                        }

                        if(isRequired){

                            this.brandsList[index].name = this.tempBrand.name;

                        }

                        let isChanged = false

                        if(this.tempBrand.name != brand.name){

                            isChanged = true;

                        }

                        if(isChanged){

                            MessageBox.confirm(
                                'You have unsaved changes, save and proceed?',
                                'Confirm',
                                {
                                    distinguishCancelAndClose: true,
                                    confirmButtonText: 'Save',
                                    cancelButtonText: 'Discard Changes',
                                }
                            ).then(async () => {

                                let loader = Loading.service({
                                    text: "The brand is being updated. Please wait!",
                                    fullscreen: true,
                                });

                                //updating brand name
                                let slug = brand.name.toLowerCase();
                                slug = slug.replace(/[^a-zA-Z0-9 ]/g, '');
                                slug = slug.replace(/\s/g, '-')
                                slug = slug.replace(/(\W)\W*/g, '$1')
                                slug = slug.replace(/-$/, "");              //eliminate final hyphen (if exists)

                                brand.name = brand.name.trim();
                                brand.slug = slug;

                                let formData = new FormData();
                                let form = brand;

                                formData.append('brand_id',form.id);
                                formData.append('name', form.name.trim());
                                formData.append('slug', form.slug);

                                try{

                                    let res = await this.$axios.post('/brand/update', formData);
                                    if(res.data.status_code == '1392'){

                                        this.$notify({
                                            type: 'success',
                                            title: 'Success',
                                            message: res.data.message,
                                        });

                                        let payload = {
                                            recordIndex: index,
                                            brand: res.data.brand
                                        }

                                        this.$store.commit('brands_module/update_brands_list',payload);

                                        this.$store.commit('gallery_module/CLEAR_MODULE');

                                    }

                                }catch(error){

                                    this.brandsList[index].name = this.tempBrand.name;
                                    this.brandsList[index].slug = this.tempBrand.slug;

                                    if(error.response){

                                        if(error.response.data.error.brand_id){

                                            this.$message({
                                                type: 'error',
                                                showClose: true,
                                                message: error.response.data.error.brand_id[0],
                                            });

                                        }else if(error.response.data.error.name){

                                            this.$message({
                                                type: 'error',
                                                showClose: true,
                                                message: error.response.data.error.name[0],
                                            });

                                        }else if(error.response.data.error.slug){

                                            this.$message({
                                                type: 'error',
                                                showClose: true,
                                                message: error.response.data.error.slug[0],
                                            });

                                        }else if(error.response.data.error.includes('storage limit has been reached')){
                                            
                                            this.$message({
                                                type: 'error',
                                                showClose: true,
                                                message: error.response.data.error,
                                            });

                                        }else{

                                            this.$message({
                                                type: 'error',
                                                showClose: true,
                                                message: error.response.data.message,
                                            });

                                        }
                                    
                                    }else{

                                        this.$message({
                                            type: "error",
                                            showClose: true,
                                            message: error.message,
                                        });

                                    }

                                }finally{

                                    loader.close();

                                }

                            }).catch(()=>{

                                this.brandsList[index].name = this.tempBrand.name;
                                this.brandsList[index].slug = this.tempBrand.slug;

                                this.$notify({
                                    type: "info",
                                    title: "Changes Discarded",
                                    message: "Changes discarded successfully.",
                                });

                                this.tempBrand = [];

                            });

                        }


                    }

                }

            }

        },
        //OTG update end

        //preview form open/close & reset start
        previewBrand(listedBrand,i,field,e){

            if(field == 'input'){

                if((e.target.tagName == "TD" || e.target.dataset.open == "true") && !this.tourIsActive){
                    
                    if(listedBrand.id != -1){

                        if(this.previewForm.id == -1){
                        
                            MessageBox.confirm(
                                "Are you sure? unsaved changes will be discarded", 
                                "Warning", 
                                {
                                    confirmButtonText: "Yes",
                                    cancelButtonText: "Cancel",
                                    type: "warning",
                                }
                            ).then(()=>{

                                let index = this.brandsList.findIndex((brand) => brand.id == -1);

                                if(index != -1){

                                    this.brandsList.splice(index,1); 

                                }

                                if(!this.brandSave){

                                    let tempIndex = this.brandsList.findIndex(x=>x.id == this.tempPreviewBrand.id);

                                    if(tempIndex != -1){

                                        this.brandsList[tempIndex] = this.tempPreviewBrand

                                    }

                                }

                                this.$refs.addPreviewFileInput.value = ''

                                this.resetImageValidations();

                                let brand = listedBrand

                                this.previewForm = brand
                                this.previewForm.index = i
                                this.previewForm.image_src = null
                                this.previewForm.image = brand.image

                                this.checkedItems = []

                                this.brandIsSaving = false;

                                this.tempPreviewBrand = _.cloneDeep(brand)

                            }).catch(()=>{})

                        }else{

                            let index = this.brandsList.findIndex((brand) => brand.id == -1);

                            if(index != -1){

                                this.brandsList.splice(index,1); 

                            }

                            if(!this.brandSave && (this.tempPreviewBrand.id != listedBrand.id)){
                                
                                let tempIndex = this.brandsList.findIndex(x=>x.id == this.tempPreviewBrand.id);

                                if(tempIndex != -1){

                                    this.brandsList[tempIndex] = this.tempPreviewBrand;

                                }

                            }

                            if(this.tempPreviewBrand.id != '' && this.tempPreviewBrand.id == listedBrand.id){

                                let tempIndex = this.brandsList.findIndex(x=>x.id == this.tempPreviewBrand.id);
                                
                                this.brandsList[tempIndex] = _.cloneDeep(this.storedBrands[tempIndex]);

                                this.$refs.previewFileInput.value = ''
                            
                                this.resetImageValidations();

                                let brand = _.cloneDeep(this.brandsList[tempIndex])

                                this.previewForm = this.brandsList[tempIndex]
                                this.previewForm.index = i
                                this.previewForm.image_src = null
                                this.previewForm.image = brand.image

                                this.checkedItems = []

                                this.brandIsSaving = false;

                                this.tempPreviewBrand = _.cloneDeep(brand)

                            }else{

                                this.$refs.previewFileInput.value = ''

                                this.resetImageValidations();

                                let brand = listedBrand

                                this.previewForm = brand
                                this.previewForm.index = i
                                this.previewForm.image_src = null
                                this.previewForm.image = brand.image

                                this.checkedItems = [];

                                this.brandIsSaving = false;

                                this.tempPreviewBrand = _.cloneDeep(brand)

                            }

                        }

                    }

                }
                
            }else{

                if(!this.tourIsActive){

                    if(listedBrand.id != -1){

                        if(this.previewForm.id == -1){

                            MessageBox.confirm(
                                "Are you sure? unsaved changes will be discarded", 
                                "Warning", 
                                {
                                    confirmButtonText: "Yes",
                                    cancelButtonText: "Cancel",
                                    type: "warning",
                                }
                            ).then(()=>{

                                let index = this.brandsList.findIndex((brand) => brand.id == -1);

                                if(index != -1){

                                    this.brandsList.splice(index,1); 

                                }

                                if(!this.brandSave){

                                    let tempIndex = this.brandsList.findIndex(x=>x.id == this.tempPreviewBrand.id);

                                    if(tempIndex != -1){

                                        this.brandsList[tempIndex] = this.tempPreviewBrand

                                    }

                                }

                                this.$refs.addPreviewFileInput.value = ''

                                this.resetImageValidations();

                                let brand = listedBrand

                                this.previewForm = brand
                                this.previewForm.index = i
                                this.previewForm.image_src = null
                                this.previewForm.image = brand.image

                                this.checkedItems = []

                                this.brandIsSaving = false;

                                this.tempPreviewBrand = _.cloneDeep(brand)

                            }).catch(()=>{})

                        }else{

                            let index = this.brandsList.findIndex((brand) => brand.id == -1);

                            if(index != -1){

                                this.brandsList.splice(index,1); 

                            }

                            if(!this.brandSave && (this.tempPreviewBrand.id != '' && this.tempPreviewBrand.id != listedBrand.id)){

                                let tempIndex = this.brandsList.findIndex(x=>x.id == this.tempPreviewBrand.id);

                                if(tempIndex != -1){
                                
                                    this.brandsList[tempIndex] = this.tempPreviewBrand
                                
                                }


                            }

                            if(this.tempPreviewBrand.id != '' && this.tempPreviewBrand.id == listedBrand.id){

                                let tempIndex = this.brandsList.findIndex(x=>x.id == this.tempPreviewBrand.id);
                                
                                this.brandsList[tempIndex] = _.cloneDeep(this.storedBrands[tempIndex])

                                this.$refs.previewFileInput.value = ''

                                this.resetImageValidations();

                                let brand = _.cloneDeep(this.brandsList[tempIndex])

                                this.previewForm = this.brandsList[tempIndex]
                                this.previewForm.index = i
                                this.previewForm.image_src = null
                                this.previewForm.image = brand.image

                                this.checkedItems = []

                                this.brandIsSaving = false;

                                this.tempPreviewBrand = _.cloneDeep(brand)

                            }else{

                                this.$refs.previewFileInput.value = ''

                                this.resetImageValidations();

                                let brand = listedBrand

                                this.previewForm = brand
                                this.previewForm.index = i
                                this.previewForm.image_src = null
                                this.previewForm.image = brand.image

                                this.checkedItems = []

                                this.brandIsSaving = false;

                                this.tempPreviewBrand = _.cloneDeep(brand)

                            }

                        }

                    }

                }

            }

        },
        previewSearchedBrand(listedBrand,i){
            
            let index = this.brandsList.findIndex((coupon) => coupon.id == -1);

            if(index != -1){

                this.brandsList.splice(index,1); 

            }

            if(!this.brandSave){

                let tempIndex = this.brandsList.findIndex(x=>x.id == this.tempPreviewBrand.id);

                if(tempIndex != -1){

                    this.brandsList[tempIndex] = this.tempPreviewBrand

                }

            }

            let brand = listedBrand;

            this.previewForm = brand
            this.previewForm.index = i
            this.previewForm.image_src = null
            this.previewForm.image = brand.image

            this.checkedItems = []

            this.resetImageValidations();

            this.tempPreviewBrand = _.cloneDeep(brand)

        },
        closePreview(){
            
            if(this.previewForm.isTouched){

                MessageBox.confirm(
                    "Do you want to close? Entered information will be discarded.", 
                    "Warning", 
                    {
                        confirmButtonText: "Yes",
                        cancelButtonText: "Cancel",
                        type: "warning",
                    }
                ).then(async () => {

                    let index = this.brandsList.findIndex((brand) => brand.id == -1);

                    if(index != -1){

                        this.brandsList.splice(index,1); 

                    }

                    if(!this.brandSave && (this.tempPreviewBrand.id != '')){

                        let tempIndex = this.brandsList.findIndex(x=>x.id == this.tempPreviewBrand.id);

                        if(tempIndex != -1){

                            this.brandsList[tempIndex] = this.tempPreviewBrand;

                        }

                    }

                    this.previewForm.id = '';

                    // this.previewForm = _.cloneDeep(this.defaultPreviewForm);

                    this.tempPreviewBrand = _.cloneDeep(this.defaultPreviewForm);

                    this.tempBrand = _.cloneDeep(this.defaultPreviewForm);

                    this.brandSave = false;

                }).catch(()=>{})

            }else{

                let index = this.brandsList.findIndex((brand) => brand.id == -1);

                if(index != -1){

                    this.brandsList.splice(index,1); 

                }

                if(!this.brandSave && (this.tempPreviewBrand.id != '')){

                    let tempIndex = this.brandsList.findIndex(x=>x.id == this.tempPreviewBrand.id);

                    if(tempIndex != -1){

                        this.brandsList[tempIndex] = this.tempPreviewBrand;

                    }

                }

                this.previewForm.id = '';

                // this.previewForm = _.cloneDeep(this.defaultPreviewForm);

                this.tempPreviewBrand = _.cloneDeep(this.defaultPreviewForm);

                this.tempBrand = _.cloneDeep(this.defaultPreviewForm);

                this.brandSave = false;

            }

        },
        resetPreview(){
            
            let index = this.brandsList.findIndex((brand) => brand.id == -1);

            if(index != -1){

                this.brandsList.splice(index,1); 

            }

            this.previewForm.id = '';

            // this.previewForm = _.cloneDeep(this.defaultPreviewForm)

            this.resetImageValidations();
            
            if(this.$refs.addPreviewFileInput){

                this.$refs.addPreviewFileInput.value = ''

            }

        },
        createBrand(){
            
            if(this.brandsLoading){

                this.$notify({
                    type: "info",
                    title: "Please Wait",
                    message: "Brands are being retrieved.",
                });

                return

            }

            if(this.brandIsSaving){

                this.$notify({
                    type: "info",
                    title: "Please Wait",
                    message: "A brand is currently being processed.",
                });

                return

            }

            let index = this.brandsList.findIndex(x=>x.id == -1);
            
            if(index != -1){

                // this.$notify({
                //   title: "Can't Add New Brand",
                //   type: "error",
                //   message: "you have an unsaved brand in the list",
                // });

                return
                
            }

            this.closePreview();

            this.previewForm = _.cloneDeep(this.defaultPreviewForm)

            this.previewForm.id = -1

            let tempBrand = this.previewForm

            this.brandsList.unshift(tempBrand)

            this.checkedItems = [];

            this.$refs.brandFormObserver.reset();

            this.resetImageValidations();

        },
        //preview form open/close & reset end

        //image add/update start
        addFormImageChangeHande(e){

            this.imageValidates = {
                imgIsRequired:false,
                imgInvalidFormat:false,
                isNotImg:false,
            }

            if(e.target.files.length > 0){

                const file = e.target.files[0];

                if(file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/webp' || file.type == 'image/png'){
                
                    this.previewForm.addImage = file;
                    this.previewForm.addImage_src = URL.createObjectURL(file);

                    this.imageValidations.imgIsRequired = false;

                    if(this.$refs.addPreviewFileInput){

                        this.$refs.addPreviewFileInput.value = '';

                    }

                }else if(file.type.includes('image/')){

                    let uploadedFileType = file.type.slice(6)
                    
                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: `${uploadedFileType.toUpperCase()} is not a valid file type.`,
                    });

                    if(!this.previewForm.addImage){

                        this.imageValidations.imgIsRequired = true;

                    }
                
                    // this.imageValidations.imgInvalidFormat = true

                }else{
                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: "Uploaded file must be an image.",
                    }); 

                    if(!this.previewForm.addImage){

                        this.imageValidations.imgIsRequired = true;

                    }

                    // this.imageValidations.isNotImg = true

                }
            }

        },
        updateFormImageChangeHandle(e){

            this.imageValidates = {
                imgIsRequired:false,
                imgInvalidFormat:false,
                isNotImg:false,
            }

            if(e.target.files.length > 0){

                const file = e.target.files[0];

                if(file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/webp' || file.type == 'image/png'){
                
                    this.previewForm.image = file;
                    this.previewForm.image_src = URL.createObjectURL(file);

                    this.imageValidations.imgIsRequired = false;

                    if(this.$refs.previewFileInput){

                        this.$refs.previewFileInput.value = '';

                    }

                }else if(file.type.includes('image/')){

                    let uploadedFileType = file.type.slice(6)
                    
                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: `${uploadedFileType.toUpperCase()} is not a valid file type.`,
                    });

                    if(!this.previewForm.image){

                        this.imageValidations.imgIsRequired = true;

                    }
                    
                    // this.imageValidations.imgInvalidFormat = true

                }else{
                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: "Uploaded file must be an image.",
                    }); 

                    if(!this.previewForm.image){

                        this.imageValidations.imgIsRequired = true;

                    }

                    // this.imageValidations.isNotImg = true

                }
                
            }

        },
        //image add/update end

        //form validation start
        validate(){

            this.$refs.brandFormObserver.validate().then(success => {

                if(!success){

                    const errors = Object.entries(this.$refs.brandFormObserver.errors)
                    .map(([key, value]) => ({ key, value })).filter(error => error["value"].length);

                    this.$refs.brandFormObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center" });

                    if(errors.length > 0){

                        if(this.previewForm.id == -1 && !this.previewForm.addImage){

                            this.imageValidations.imgIsRequired = true;

                        }else if(this.previewForm.id != -1 && !this.previewForm.image){

                            this.imageValidations.imgIsRequired = true;

                        }

                    }else{

                        if(this.previewForm.id == -1 && !this.previewForm.addImage){

                            this.imageValidations.imgIsRequired = true;

                            document.querySelector("#brand-image")
                            .scrollIntoView({ behavior: 'smooth',block: "center" });

                        }else if(this.previewForm.id != -1 && !this.previewForm.image){

                            this.imageValidations.imgIsRequired = true;

                            document.querySelector("#brand-image-update")
                            .scrollIntoView({ behavior: 'smooth',block: "center" });

                        }

                    }
                }else{

                    if(this.previewForm.id == -1 && !this.previewForm.addImage){

                        this.imageValidations.imgIsRequired = true;

                        document.querySelector("#brand-image")
                        .scrollIntoView({ behavior: 'smooth',block: "center" });

                    }else if(this.previewForm.id != -1 && !this.previewForm.image){

                        this.imageValidations.imgIsRequired = true;

                        document.querySelector("#brand-image-update")
                        .scrollIntoView({ behavior: 'smooth',block: "center" });

                    }else if(this.previewForm.id == -1){

                        this.resetImageValidations();
                        
                        this.addBrandHandle();
                    
                    }else{

                        this.resetImageValidations();

                        this.updateBrandHandle();

                    }

                }

            });

        },
        //form validation end

        //add/update & delete brand start
        async addBrandHandle(){

            if(!this.previewForm.addImage){

                this.$notify({
                    type: 'error',
                    title: 'Alert',
                    message: "Brand image is required.",
                });

                this.imageValidations.imgIsRequired = true;

                document.querySelector("#brand-image")
                .scrollIntoView({ behavior: 'smooth',block: "center" });

                return

            }

            let formData = new FormData();

            formData.append('name', this.previewForm.name.trim());
            formData.append('slug', this.previewForm.slug.replace(/-$/, ""));
            formData.append('description', this.previewForm.description);
            formData.append('isActive', this.previewForm.isActive ? 1 : 0);
            formData.append('isIndexed', this.previewForm.isIndexed ? 1 : 0);

            if(this.previewForm.meta_title){

                formData.append('meta_title', this.previewForm.meta_title);

            }

            if(this.previewForm.meta_description){

                formData.append('meta_description', this.previewForm.meta_description);

            }

            if(this.previewForm.addImage){

                formData.append('image', this.previewForm.addImage);

            }

            this.brandIsSaving = true;
            try{
                let res = await this.$axios.post('/brand/add', formData);
                if(res.data.status_code == '1391'){

                    this.brandIsSaving = false;
                    
                    this.$notify({
                        type: 'success',
                        title: 'Success',
                        message: res.data.message,
                    });

                    this.resetPreview();

                    this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

                    this.$store.commit('brands_module/add_brands_list',res.data.brand);

                    this.$store.commit('gallery_module/CLEAR_MODULE');

                }
            }catch(error){

                this.brandIsSaving = false;

                if(error.response){

                    if(error.response.data.error.name){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error.name[0],
                        });

                    }else if(error.response.data.error.slug){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error.slug[0],
                        });

                    }else if(error.response.data.error.includes('storage limit has been reached')){
                        
                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error,
                        });

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.message,
                        });

                    }
                }else{

                    this.$message({
                        type: "error",
                        showClose: true,
                        message: error.message,
                    });

                }

            }

        },
        async updateBrandHandle(){
            
            if(!this.previewForm.image && !this.previewForm.image_src){

                this.$notify({
                    type: 'error',
                    title: 'Alert',
                    message: "Brand image is required.",
                });

                this.imageValidations.imgIsRequired = true;

                document.querySelector("#brand-image-update")
                .scrollIntoView({ behavior: 'smooth',block: "center" });

                return

            }

            let formData = new FormData();

            formData.append('brand_id',this.previewForm.id);
            
            if(this.previewForm.name != this.tempPreviewBrand.name){
                formData.append('name',this.previewForm.name.trim());
            }
            if(this.previewForm.slug != this.tempPreviewBrand.slug){
                formData.append('slug', this.previewForm.slug.replace(/-$/, ""));
            }
            if(this.previewForm.description != this.tempPreviewBrand.description){
                formData.append('description',this.previewForm.description);
            }
            if(this.previewForm.isActive != this.tempPreviewBrand.isActive){
                formData.append('isActive', this.previewForm.isActive ? 1 : 0);
            }
            if(this.previewForm.isIndexed != this.tempPreviewBrand.isIndexed){
                formData.append('isIndexed', this.previewForm.isIndexed ? 1 : 0);
            }
            if(this.previewForm.meta_title != this.tempPreviewBrand.meta_title){
                formData.append('meta_title',this.previewForm.meta_title);
            }
            if(this.previewForm.meta_description != this.tempPreviewBrand.meta_description){
                formData.append('meta_description',this.previewForm.meta_description);
            }
            if(this.previewForm.image != this.tempPreviewBrand.image){
                formData.append('image', this.previewForm.image);
            }

            this.brandIsSaving = true
            try{
                let res = await this.$axios.post('/brand/update', formData);
                if(res.data.status_code == '1392'){
                    
                    this.brandIsSaving = false

                    this.$notify({
                        type: 'success',
                        title: 'Success',
                        message: res.data.message,
                    });

                    let brandIndex = this.brandsList.findIndex((listedBrand) => listedBrand.id == res.data.brand.id);

                    let payload = {
                        recordIndex: brandIndex,
                        brand: res.data.brand
                    }

                    this.brandsList[brandIndex] = res.data.brand

                    this.$store.commit('brands_module/update_brands_list',payload);

                    this.$store.commit('gallery_module/CLEAR_MODULE');

                    if(this.previewForm.id == res.data.brand.id){

                        this.brandSave = true;

                        this.closePreview();

                    }

                }
            }catch(error){

                this.brandIsSaving = false;

                if(error.response){

                    if(error.response.data.error.brand_id){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error.brand_id[0],
                        });

                    }else if(error.response.data.error.name){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error.name[0],
                        });

                    }else if(error.response.data.error.slug){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error.slug[0],
                        });

                    }else if(error.response.data.error.includes('storage limit has been reached')){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error,
                        });

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.message,
                        });

                    }

                }else{

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.message,
                    });

                }

            }

        },
        async deleteBrandHandle(brand,index){
            
            MessageBox.confirm(
                'Are you sure you want to delete the brand?',
                'Warning',
                {
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'Cancel',
                    type: 'warning',
                }
            ).then(async ()=>{

                let loader = Loading.service({
                    text: "The brand is being removed. Please wait!",
                    fullscreen: true,
                });

                this.deletingItems.push(brand.id);
                this.deleteLoader = true;

                try{
                    let res = await this.$axios.delete(`/brand/remove/${brand.id}`);
                    if(res.data.status_code == '1393'){
                        
                        this.$notify({
                            type: 'success',
                            title: 'Success',
                            message: res.data.message,
                        });

                        this.$store.commit('brands_module/delete_brands_list',index);

                        this.$store.dispatch("products_module/fetchStoreProductsRequest",{ size: 15, page: 1, loadData: 'new'});

                        this.$store.commit('gallery_module/CLEAR_MODULE');

                        this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

                        this.deleteLoader = false;
                        this.deletingItems = [];

                        //removing deleted item from checked items list
                        this.checkedItems = this.checkedItems.filter((item) => {

                            if(item != brand.id){
                                
                                return item;
                                
                            }

                        });

                    }
                }catch(error){

                    this.deleteLoader = false;
                    this.deletingItems = [];

                    if(error.response){

                        if(error.response.data.error.brand_id){

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.response.data.error.brand_id[0],
                            });

                        }else if(error.response.data.error.name){

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.response.data.error.name[0],
                            });

                        }else if(error.response.data.error.slug){

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.response.data.error.slug[0],
                            });

                        }else if(error.response.data.error.includes('storage limit has been reached')){

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.response.data.error,
                            });

                        }else{

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.response.data.message,
                            });

                        }

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.message,
                        });

                    }

                }finally{

                    loader.close();

                }

            }).catch(()=>{})

        },
        //add/update & delete brand end

        //brand status change start
        async updateBrandStatus(brand,index){

            if(!this.tourIsActive){
                
                let loader = Loading.service({
                    text: "The brand is undergoing status update. Please wait!",
                    fullscreen: true,
                });

                let formData = new FormData();

                formData.append('brand_id',brand.id);
                formData.append('isActive', brand.isActive ? 0 : 1)

                try{
                    let res = await this.$axios.post('/brand/update', formData);
                    if(res.data.status_code == '1392'){

                        this.$notify({
                            type: 'success',
                            title: 'Success',
                            message: res.data.message,
                        });

                        let payload = {
                            recordIndex: index,
                            brand: res.data.brand
                        }

                        this.$store.commit('brands_module/update_brands_list',payload);

                        this.$store.commit('gallery_module/CLEAR_MODULE');

                    }
                }catch(error){

                    brand.isActive = !brand.isActive;

                    if(error.response){

                        if(error.response.data.error.brand_id){

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.response.data.error.brand_id[0],
                            });

                        }else{

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.response.data.message,
                            });

                        }

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.message,
                        });

                    }

                }finally{

                    loader.close();

                }

            }

        },
        //brand status change end

        //bulk action start
        async brandsBulkActionHandle(action){
            
            this.resetPreview();

            MessageBox.confirm(
                'Do you really want to make the changes?',
                'Confirm',
                {
                    distinguishCancelAndClose: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                }
            ).then(async ()=>{

                if(action == 'active' || action == 'disable'){

                    let loader = Loading.service({
                        text: "The selected brands are undergoing status update. Please wait!",
                        fullscreen: true,
                    });

                    let formData = new FormData();

                    formData.append('action',action);

                    for(let i = 0; i < this.checkedItems.length; i++){

                        formData.append(`brands[${i}]`,this.checkedItems[i]);

                    }

                    try{
                        let res = await this.$axios.post('/brand/bulk-action',formData);
                        if(res.data.status_code == '2031'){

                            this.$notify({
                                type: 'success',
                                title: 'Success',
                                message: "Brands status has been successfully changed.",
                            });

                            let payload = {
                                action: 'statusChange',
                                status: action == "active" ? 1 : 0,
                                listIds: this.checkedItems
                            }

                            this.$store.commit('brands_module/brands_bulk_action',payload);

                            this.checkedItems = [];

                        }
                        
                    }catch(error){

                        if(error.response){

                            if(error.response.data.message){

                                this.$message({
                                    type: 'error',
                                    showClose: true,
                                    message: error.response.data.message,
                                });

                            }else{

                                this.$message({
                                    type: 'error',
                                    showClose: true,
                                    message: "One or more of the selected brands may have been deleted or didn't available.",
                                });

                            }

                        }else{

                            this.$message({
                                type: "error",
                                showClose: true,
                                message: error.message,
                            });

                        }

                    }finally{

                        loader.close();

                    }

                }else if(action == 'delete'){

                    let loader = Loading.service({
                        text: "The selected brands are being removed. Please wait!",
                        fullscreen: true,
                    });

                    let formData = new FormData();

                    formData.append('action',action);

                    for(let i = 0; i < this.checkedItems.length; i++){

                        formData.append(`brands[${i}]`,this.checkedItems[i]);

                    }

                    this.deletingItems = _.cloneDeep(this.checkedItems);
                    this.deleteLoader = true;

                    try{
                        let res = await this.$axios.post('/brand/bulk-action',formData);
                        if(res.data.status_code == '2031'){

                            this.$notify({
                                type: 'success',
                                title: 'Success',
                                message: "Brands have been successfully removed.",
                            });

                            let payload = {
                                action: 'delete',
                                listIds: this.checkedItems
                            }

                            this.$store.commit('brands_module/brands_bulk_action',payload);

                            this.$store.commit('gallery_module/CLEAR_MODULE');

                            this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

                            this.checkedItems = [];

                        }
                    }catch(error){

                        if(error.response){

                            if(error.response.data.message){

                                this.$message({
                                    type: 'error',
                                    showClose: true,
                                    message: error.response.data.message,
                                });

                            }else{

                                this.$message({
                                    type: 'error',
                                    showClose: true,
                                    message: "One or more of the selected brand may have been deleted or didn't available.",
                                });

                            }

                        }else{

                            this.$message({
                                type: "error",
                                showClose: true,
                                message: error.message,
                            });

                        }

                    }finally{

                        loader.close();

                        this.deletingItems = [];
                        this.deleteLoader = false;

                    }

                }

            }).catch(()=>{})

        },
        //bulk action end

        //search brand start
        async searchBrand(){

            this.resetPreview();

            this.brandsLoading = true;

            clearTimeout(this.timer)
            
            this.timer = setTimeout(() => {

                if(this.searchedItem.length > 0){

                    let payload = {
                        keyword: this.searchedItem,
                    }

                    this.$store.commit('brands_module/update_searched_keyword',payload);
                    this.$store.dispatch('brands_module/fetchBrandsRequest',{ size:15, page:1, loadData: 'new'});

                }else{

                    this.$store.commit('brands_module/remove_searched_keyword');
                    this.$store.dispatch('brands_module/fetchBrandsRequest',{size:15, page:1, loadData: 'new'});

                }
                
            }, 500)

        },
        clearSearch(){

            this.searchedItem = ''

            this.searchBrand();

        },
        //search brand end

        //other methods start
        resetImageValidations(){
            
            this.imageValidations = {
                imgIsRequired:false,
                imgInvalidFormat:false,
                isNotImg:false,
            }

        },
        generateUrl(brand){
            
            if(brand.image){

                if(typeof(brand.image) == "string"){

                    return this.base_image_url + this.setting.store_id + '/brand/' + brand.image;

                }else{

                    return brand.image_src;

                }

            }
            
        },
        isDeletingItem(id){

            let find = this.deletingItems.find(x=>x == id);

            if(find){

                return true;

            }else{

                return false;

            }

        },
        toggleIsCheckedAll(){

            if(!this.tourIsActive && !this.previewForm.id){

                if((this.checkedItems.length == this.brandsList.length)){

                    this.checkedItems = [];

                }else if(this.checkedItems.length < this.brandsList.length){

                    this.checkedItems = [];
                    
                    this.brandsList.forEach(brand => {

                        if(brand.id != -1){

                            this.checkedItems.push(brand.id);

                        }

                    });

                }

            }
        },
        clearSelection(){

          this.checkedItems = [];

        },
        checkedItemHandle(brandId){

            if(!this.tourIsActive && brandId != -1 && !this.previewForm.id){

                let index = this.checkedItems.findIndex(item => item == brandId);

                if(index != -1){

                    this.checkedItems.splice(index,1);

                }else{

                    this.checkedItems.push(brandId);

                }

            }
            
        },
        expand(){

            this.$refs.viewInputForm.classList.toggle('fullWidthSlide');

        },
        keyEvent(e){

            if(e.key == 'Escape'){

                if(this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

                    this.$refs.viewInputForm.classList.remove('fullWidthSlide');

                }else if(this.$refs.viewInputForm.classList.contains('activeRow')){

                    this.closePreview();

                }

            }

        },
        //other methods end
        //tour methods start
        startBrandTour(){

            this.$tours['brandsTour'].start();

            this.tourIsActive = true;

            if(this.previewForm.id != ''){

                this.closePreview();

            }

        },
        handleNextStep(currentStep){

            if(currentStep + 1 == 7){

                document.querySelector(".tableActionBtns").style.position = "static";

                document.querySelector(".productSlideBox").style.overflowY  = "hidden";

                this.createBrand();

            }

            if(currentStep >= 16){

                document.querySelector(".tableActionBtns").style.position = "sticky";

            }

            if(currentStep + 1 == 19){

                this.expand();

            }

            if(currentStep + 1 == 20){

                if(this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

                    this.expand();

                } 

            }

        },
        handlePreviousStep(currentStep){

            if(currentStep == 7){

                this.resetPreview();

                document.querySelector(".tableActionBtns").style.position = "sticky";

                document.querySelector(".productSlideBox").style.overflowY  = "auto";

            }

            if(currentStep <= 17){

                document.querySelector(".tableActionBtns").style.position = "static";

            }

            if(currentStep == 19){

                if(this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

                    this.expand();

                }

            }

            if(currentStep == 20){

                this.expand();

            }

        },
        handleTourStop(){

            document.querySelector(".tableActionBtns").style.position = "sticky";

            document.querySelector(".productSlideBox").style.overflowY  = "auto";

            this.closePreview();

            this.tourIsActive = false;

        },
        handleTourFinish(){

            document.querySelector(".tableActionBtns").style.position = "sticky";

            document.querySelector(".productSlideBox").style.overflowY  = "auto";

            this.closePreview();

            this.tourIsActive = false;

        },
        handleTourSkip(){

            document.querySelector(".tableActionBtns").style.position = "sticky";

            document.querySelector(".productSlideBox").style.overflowY  = "auto";

            this.closePreview();

            this.tourIsActive = false;

        },
        //tour methods end

        //image loading error handling
        handleImageError(e){

            e.target.src = '/images/image_not_found.png'

        },

    },
    mounted(){

        this.$refs.brandsTable.addEventListener('scroll', this.handleScrollEvent);

        window.addEventListener('keydown',this.keyEvent);

        window.addEventListener('beforeunload', (event) => {

            let index = null;

            this.brandsList.forEach((brand) => {

                if(brand.id == -1){

                    index = this.brandsList.indexOf(brand);

                }

            });

            if(index != null){

                event.returnValue = "Are you sure you want to leave? entered information will be lost";

            }

        });

        if(this.$route.query.search){
      
            this.searchedItem = this.$route.query.search

            if(this.$route.query.preview == 1){

                this.isMasterSearched = true;

            }

            this.$router.replace({'query': null});

            this.searchBrand();

        }

        //add form top navigation scroll handle
        this.$refs.viewInputForm.addEventListener("scroll",(e)=>{

            let maxScrollTop = e.target.scrollHeight - e.target.clientHeight;

            if(e.target.scrollTop >= maxScrollTop){

                document.querySelectorAll(".sectionTabsLink ul li a.active")
                .forEach(function (item) {
                    item.classList.remove("active");
                });

                if(!this.tourIsActive){

                    document.querySelector('#category-seo-navbtn').classList.add('active');

                }

            }else{

                document.querySelectorAll(".sectionTabsLink ul li a.active")
                .forEach(function (item) {
                    item.classList.remove("active");
                });

                if(!this.tourIsActive){

                    document.querySelector('#category-detail-navbtn').classList.add('active');

                }

            }

        });

    },
    beforeMount(){

        try{
            if((!this.storedBrands || this.storedBrands.length == 0) && this.brandsSearchKeyword.length == 0){
            
                this.brandsLoading = true;

                this.$store.dispatch('brands_module/fetchBrandsRequest',{ size: 15 ,page: 1, loadData:'new' });

            }else{

                this.brandsList = _.cloneDeep(this.storedBrands);

                this.brandsLoading = false;

            }

            if(this.brandsSearchKeyword.length > 0){

                this.searchedItem = this.brandsSearchKeyword;
                
            }

        }catch(error){

            this.brandsLoading = false;

            this.$message({
                type: 'error',
                showClose: true,
                message: error.response ? error.response.data.message : error.message,
            });

        }

    },
    beforeDestroy(){

        window.removeEventListener("keydown", this.keyEvent);

    },
    beforeRouteLeave(to, from, next){

        let index = null;

        this.brandsList.forEach((brand) => {

            if (brand.id == -1) {

                index = this.brandsList.indexOf(brand);

            }

        });

        if(window.innerWidth <= 1024){

            document.body.classList.remove('activeIpad');
            document.body.classList.add('hideSideBar');

        }

        if((this.previewForm.id || index != null) && to.fullPath != '/login'){

            MessageBox.confirm(
                'You may have unsaved changes, Proceeding will discard them',
                'Confirm',
                {
                    distinguishCancelAndClose: true,
                    confirmButtonText: 'Process',
                    cancelButtonText: 'Stay',
                }
            ).then(()=>{

                this.resetPreview();

                next();

            }).catch(()=>{});

        }else{

            next();

        }

    },
};

window.myFunction = function (event) {
    // reset all menu items
    document.querySelectorAll(".sectionTabsLink ul li a.active").forEach(function (item) {
        item.classList.remove("active");
    });
    // mark as active selected menu item
    event.target.classList.add("active");
};

</script>

<style>
    .viewOrderBox tbody tr td {
        cursor: inherit !important;
    }
    .category-tab-img {
        height: 400px;
        width: 400px;
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        overflow: hidden;
        position: relative;
    }
    .category-tab-img:hover {
        border-color: #409EFF;
    }
    .category-tab-img img{
        height: 100%;
        width: 100%;
    }
    .category-tab-img svg{
        position: absolute;
        width: 50px;
        top: 43%;
        left: 0;
        right: 0;
        margin: 0 auto;
        fill: #d9d9d9;
    }
    .category-tab-img:hover svg{
    fill: #409EFF;
    }
    .custom-form.create-section .ql-editor {
        color: #000 !important;
    }
    .ql-editor{
        height: 200px;
    }

    /* States Css Start Here */
    .ctmDashboardCardRow{
        display: flex;
        align-items: center;
        gap: 15.4px;
        flex-wrap: wrap;
    }
    .statesWrapBlock{
        width: 15.83%;
    }
    .statesWrapBlock p{
        font-size: 14px;
        color: #000000;
        margin-bottom: 0px;
        font-weight: 400;
    }
    .statesWrapBlock p span{
        display: inline-flex;
        align-items: center;
        margin-left: 5.5px;
    }
    .statesWrapBlock p span svg{
        display: inline-block;
        width: 8.511px;
        height: 9.726px;
        margin-right: 2.5px;
    }
    .statesWrapBlock p span svg:nth-last-child(1){
        margin-right: 0px;
    }
    .ctmDashboardCardItem{
        width: 100%;
        padding: 12px 17px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .ctmDashboardCardItem.lightBlueCart{
        background-color: #1EC2C2;
    }
    .ctmDashboardCardItem.lightYellowCart{
        background-color: #E0E847;
    }
    .ctmDashboardCardItem.lightRedCart{
        background-color: #FD4D5D;
    }
    .orderStatesTxtBlock{
        width: 144px;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }
    .statesTxtField{
        text-align: center;
    }
    .statesTxtField h4{
        font-size: 30px;
        margin-bottom: 0px;
        color: #fff;
        font-weight: 600;
    }
    .statesTxtField p{
        font-size: 12px;
        margin-bottom: 0px;
        color: #fff;
        font-weight: 400;
    }
    .statesTxtField.weekDetailTxt{
        opacity: 0.9;
    }
    .statesTxtField.weekDetailTxt h4{
        font-size: 24px;
        margin-bottom: 0px;
        color: #fff;
        font-weight: 600;
    }
    .statesTxtField.weekDetailTxt p{
        font-size: 8px;
        margin-bottom: 0px;
        color: #fff;
        font-weight: 400;
    }
    .statesTxtField.monthlyDetailTxt{
        opacity: 0.8;
    }
    .statesTxtField.monthlyDetailTxt h4{
        font-size: 19px;
        margin-bottom: 0px;
        color: #fff;
        font-weight: 600;
    }
    .statesTxtField.monthlyDetailTxt p{
        font-size: 8px;
        margin-bottom: 0px;
        color: #fff;
        font-weight: 400;
    }
    .cardTxtWrap h4{
        margin-bottom: 0px !important;
        color: #fff;
        font-size: 21px;
        font-weight: 600;
        display: flex;
        align-items: flex-end;
    }
    .cardTxtWrap h4 span{
        display: inline-block;
        margin-bottom: 0px !important;
        color: #15223D;
        font-size: 10px;
        font-weight: 500;
        position: relative;
        top: -5px;
        left: 3px
    }
    .cardTxtWrap p{
        margin-bottom: 0px !important;
        color: #fff;
        font-size: 12px;
        font-weight: 300;
    }
    .cardIconWrap{
        width: 57px;
        height: 57px;
        border-radius: 50%;
        background: #fff;
        padding: 12px;
    }
    .lightBlueCart .cardIconWrap{
        background: #2CCBCB;
    }
    .lightYellowCart .cardIconWrap{
        background: #D4DC38;
    }
    .lightYellowCart .cardTxtWrap h4{
        color: #15223D;
    }
    .lightYellowCart .cardTxtWrap p{
        color: #15223D;
    }
    .lightRedCart .cardIconWrap{
        background: #EF3F4F;
    }
    .cardIconWrap img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .el-notification{
        z-index: 10000000 !important;
    }
</style>